import React, {useEffect, useState} from 'react';
import useSignUpForm from '../../assets/CustomHooks';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {Request} from '../../data/api';
import {useHistory} from 'react-router-dom';
import {showToast} from '../../assets/loadingToast';

const AddUser = () => {
    const [allCustomers, setAllCustomers] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getAllCustomers();
    }, []);

    const signup = async () => {
        const data = {
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            realm: null,
            username: inputs.email,
            emailVerified: false,
            password: inputs.password,
            customers: inputs.customers,
            isAdmin: inputs.isAdmin
        };
        await Request('user', 'POST', data).then(() => {
            showToast('Gebruiker toegevoegd', 'success');
            history.push('/users');
        }).catch((error) => {
            showToast(`Gebruiker toevoegen mislukt.\n${error}`, 'error');
        });
    };

    const {
        touched,
        inputs,
        handleInputChange,
        handleCheckboxChange,
        handleMultiselectChange,
        handleSubmit,
        handleBlur,
        isValidEmail
    } = useSignUpForm({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordCheck: '',
        customers: [],
        isAdmin: false
    }, signup);

    const validateForm = (inputs) => {
        return {
            firstName: (inputs.firstName.length === 0 || /\d/.test(inputs.firstName)),
            lastName: (inputs.lastName.length === 0 || /\d/.test(inputs.lastName)),
            email: !isValidEmail(inputs.email),
            password: inputs.password.length < 8,
            passwordCheck: inputs.passwordCheck !== inputs.password
        };
    };

    const getAllCustomers = () => {
        Request('customer', 'GET').then((response) => {
            const sortedCustomers = response.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
            setAllCustomers(sortedCustomers);
            return sortedCustomers;
        }).catch((error) => {
            showToast('Alle klanten ophalen mislukt.\n' + error, 'error');
            history.push('/');
        });
    };

    const errors = validateForm(inputs);

    const shouldMarkError = (field) => {
        const hasError = errors[field];
        const shouldShow = touched[field];
        return hasError ? shouldShow : false;
    };

    return (
        <div className="add-user-form" style={{maxWidth: '50%'}}>
            <h3>Gebruiker Toevoegen</h3>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label htmlFor="firstName">Voornaam</Label>
                    <Input
                        type="text" required placeholder="Vul je voornaam in"
                        name="firstName"
                        id="firstName"
                        value={inputs.firstName}
                        onChange={handleInputChange}
                        valid={touched['firstName'] ? !shouldMarkError('firstName') : false}
                        onBlur={() => handleBlur('firstName')}
                        invalid={shouldMarkError('firstName')}
                    />
                    <FormFeedback>Vul op z'n minst een letter in.</FormFeedback>

                </FormGroup>
                <FormGroup>
                    <Label htmlFor="lastName">Achternaam</Label>
                    <Input
                        type="text" placeholder="Vul je achternaam in" name="lastName"
                        value={inputs.lastName}
                        onChange={handleInputChange}
                        valid={touched['lastName'] ? !shouldMarkError('lastName') : false}
                        onBlur={() => handleBlur('lastName')}
                        invalid={shouldMarkError('lastName')}
                    />
                    <FormFeedback>Vul op z'n minst een letter in.</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="email">Email adres</Label>
                    <Input
                        type="text" placeholder="Vul je email adres in" name="email"
                        value={inputs.email} onChange={handleInputChange}
                        valid={touched['email'] ? !shouldMarkError('email') : false}
                        onBlur={() => handleBlur('email')}
                        invalid={shouldMarkError('email')}
                    />
                    <FormFeedback>Vul een geldig email adres in (test@test.com).</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="password">Wachtwoord</Label>
                    <Input
                        type="password"
                        placeholder="Vul je wachtwoord in"
                        name="password"
                        value={inputs.password} onChange={handleInputChange}
                        onBlur={() => handleBlur('password')}
                        valid={touched['password'] ? !shouldMarkError('password') : false}
                        invalid={shouldMarkError('password')}
                    />
                    <FormFeedback>Vul op z'n minst 8 tekens in.</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="email">Wachtwoord check</Label>
                    <Input
                        type="password"
                        placeholder="Vul je wachtwoord nogmaals"
                        name="passwordCheck"
                        value={inputs.passwordCheck} onChange={handleInputChange}
                        onBlur={() => handleBlur('passwordCheck')}
                        valid={touched['passwordCheck'] ? !shouldMarkError('passwordCheck') : false}
                        invalid={shouldMarkError('passwordCheck')}
                    />
                    <FormFeedback>De wachtwoorden komen niet overeen.</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="customer-select">Selecteer klanten om aan deze gebruiker te koppelen</Label>
                    <Input type="select" name="customers" onChange={handleMultiselectChange} multiple>
                        {(allCustomers && allCustomers.length > 0) && allCustomers.map((customer, index) => {
                            return <option name="customers" value={customer.id} key={`customers-option-${index}`}>{customer.name}</option>;
                        })}
                    </Input>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="isAdmin" checked={inputs.isAdmin} onChange={handleCheckboxChange}/>
                        Is admin?
                    </Label>
                </FormGroup>
                <Button color="primary" size="lg" block style={{width: 'auto', marginLeft: 'auto'}}>
                    Gebruiker aanmaken
                </Button>
            </Form>
        </div>
    );
};

export default AddUser;
