import _ from 'lodash';
import {handleLocationResult, Request} from './api';

export const transformLocationsToAddress = (locations) => {
    return locations.map((item) => {
        return {
            city: item.city,
            postCode: item.postcode ? item.postcode : '',
            country: item.country ? item.country : ''
        };
    });
};

export const getCoordinates = async (input) => {
    const addresses = transformLocationsToAddress(_.uniqBy(input, 'city'));
    const result = await Request('location/xlocate/get-bulk-locations', 'POST', addresses);
    return handleLocationResult(result, addresses);
};
