import React, {Component} from 'react';
import {isAdmin, Request} from '../../data/api';
import {Link} from 'react-router-dom';
import {closeLoadingToast, showToast} from '../../assets/loadingToast';

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            customerName: '',
            allCustomer: []
        };
    }

    async componentDidMount() {
        await Request('/user/', 'GET').then((response) => {
            this.setState({
                customers: response.customers
            });
        }).catch((error) => {
            showToast('Kon gebruiker niet ophalen.\n' + error, 'error');
        });
        await this.getAllCustomers();
        closeLoadingToast();
    }

    getAllCustomers = async () => {
        if (isAdmin()) {
            await Request('customer', 'GET').then((response) => {
                this.setState({allCustomers: response});
            }).catch((error) => console.log(error));
        }
    };

    setCustomerName = (event) => {
        this.setState({
            customerName: event.target.value
        });
    };

    createNewCustomer = async () => {
        if (isAdmin()) {
            const {customerName} = this.state;
            if (!customerName) {
                return;
            }

            await Request('customer', 'POST', {name: customerName}).then(() => {
                showToast(`Gebruiker "${customerName}" aangemaakt.`, 'success');
                this.getAllCustomers();
            }).catch((error) => {
                showToast(`Fout bij het aanmaken van gebruiker "${customerName}".\n${error}`, 'error');
            });

            this.setState({customerName: ''});
        }
    };

    render() {
        const renderCustomers = (customersToRender) => {
            return customersToRender.map((customer) => {
                return (
                    <li className="list-group-item" key={customer.id} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <h5 style={{flex: 1, marginBottom: 0}}>{customer.name}</h5>
                        <Link className="btn btn-primary" style={{marginRight: '20px'}}
                              to={{pathname: `/tenders/${customer.id}/`, state: {customerId: customer.id}}}>Tenders</Link>
                        <Link className="btn btn-primary" to={{pathname: `/tenderHandler`, state: {customerId: customer.id}}}>Tender
                            Toevoegen</Link>
                    </li>);
            });
        };
        return (
            <div>
                <h3>Gekoppelde klanten</h3>

                {this.state.customers && this.state.customers.length > 0 ?
                    <ul className="list-group" style={{maxWidth: '50%'}}>
                        {renderCustomers(this.state.customers)}
                    </ul> :
                    <h4>Geen klanten aan dit account gekoppeld</h4>
                }
                {isAdmin() &&
                <div style={{marginTop: '40px'}}>
                    <h4>Alle klanten (Admin view)</h4>
                    <div className="input-group" style={{width: '50%', marginBottom: '10px'}}>
                        <div className="input-group-prepend">
                            <input className="btn btn-primary" type="submit"
                                   onClick={() => this.createNewCustomer()}
                                   value="Toevoegen"/>
                        </div>
                        <input
                            className="form-control"
                            placeholder='Vul hier een nieuwe klantnaam in...'
                            type="text"
                            value={this.state.customerName}
                            onChange={this.setCustomerName} style={{'padding': '10px'}}
                        />
                    </div>
                    {this.state.allCustomers && this.state.allCustomers.length > 0 &&
                    <ul className="list-group" style={{maxWidth: '50%'}}>
                        {renderCustomers(this.state.allCustomers)}
                    </ul>
                    }
                </div>
                }
            </div>
        );
    }
}

export default Customers;
