import React from 'react';
import useSignUpForm from '../../assets/CustomHooks';
import {Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {logout, Request} from '../../data/api';
import {showToast} from '../../assets/loadingToast';

const ChangePassword = () => {

    const changePassword = async () => {
        const {oldPassword, newPassword} = inputs;
        await Request('user/change-password', 'POST', {
            oldPassword,
            newPassword
        }).then(() => {
            reset();
            showToast('Wachtwoord gewijzigd. Je wordt nu uitgelogd zodat je opnieuw kunt inloggen met je nieuwe wachtwoord.', 'warning', 3000, {onClose: () => logout()});
        }).catch((error) => {
            reset();
            showToast('Wachtwoord wijzigen mislukt.\n' + error, 'error');
        });
    };

    const {touched, inputs, reset, handleInputChange, handleSubmit, handleBlur} = useSignUpForm({
        oldPassword: '',
        newPassword: '',
        passwordCheck: ''
    }, changePassword);

    const validateForm = (inputs) => {
        return {
            oldPassword: inputs.oldPassword.length < 7,
            newPassword: inputs.newPassword.length < 7,
            passwordCheck: inputs.passwordCheck !== inputs.newPassword
        };
    };

    const errors = validateForm(inputs);

    const shouldMarkError = (field) => {
        const hasError = errors[field];
        const shouldShow = touched[field];
        return hasError ? shouldShow : false;
    };
    return (
        <Card>
            <CardBody>
                <p className="text-muted">Wijzig je wachtwoord</p>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label htmlFor="password">Oud wachtwoord</Label>
                        <Input
                            type="password"
                            placeholder="Vul je wachtwoord in"
                            name="oldPassword"
                            value={inputs.oldPassword} onChange={handleInputChange}
                            onBlur={() => handleBlur('oldPassword')}
                            valid={touched['password'] ? !shouldMarkError('password') : false}
                            invalid={shouldMarkError('oldPassword')}
                        />
                        <FormFeedback>Vul op z'n minst 8 tekens in.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="password">Nieuw wachtwoord</Label>
                        <Input
                            type="password"
                            placeholder="Vul je wachtwoord in"
                            name="newPassword"
                            value={inputs.newPassword} onChange={handleInputChange}
                            onBlur={() => handleBlur('newPassword')}
                            valid={touched['password'] ? !shouldMarkError('password') : false}
                            invalid={shouldMarkError('newPassword')}
                        />
                        <FormFeedback>Vul op z'n minst 8 tekens in.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="email">Wachtwoord check</Label>
                        <Input
                            type="password"
                            placeholder="Vul je wachtwoord nogmaals"
                            name="passwordCheck"
                            value={inputs.passwordCheck} onChange={handleInputChange}
                            onBlur={() => handleBlur('passwordCheck')}
                            valid={touched['passwordCheck'] ? !shouldMarkError('passwordCheck') : false}
                            invalid={shouldMarkError('passwordCheck')}
                        />
                        <FormFeedback>De wachtwoorden komen niet overeen.</FormFeedback>
                    </FormGroup>
                    <Button color="primary" size="lg" block>Wachtwoord wijzigen</Button>
                </Form>
            </CardBody>
        </Card>
    );
};

export default ChangePassword;
