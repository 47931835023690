import React, {Component} from 'react';
import {Button, Card, CardText, CardTitle, Col, Input, Row} from 'reactstrap';
import {closeLoadingToast, renderLoadingToast, showToast} from '../../assets/loadingToast';
import {Request, setIsAdmin, storeToken} from '../../data/api';
import _ from 'lodash';
import {Redirect} from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            redirectToReferrer: false
        };
    }

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        });
    };

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value
        });
    };

    postLogin = _.debounce(async () => {
        renderLoadingToast('Inloggen...');
        if (!this.state.email || !this.state.password) {
            return;
        }
        await Request('app/login', 'POST', {username: this.state.email, password: this.state.password}).then((response) => {
            storeToken(response.access_token);
            setIsAdmin(response.user.isAdmin);
            this.setState({redirectToReferrer: true});
        }).catch((error) => {
            closeLoadingToast();
            showToast('Je email of wachtwoord is incorrect.\n' + error, 'error');
        });
    }, 250, {
        leading: true,
        trailing: false
    });

    render() {
        let {redirectToReferrer} = this.state;
        if (redirectToReferrer) return <Redirect exact to={{pathname: '/', state: {loggedIn: true}}}/>;
        return (
            <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px'}}>
                <Col sm="3">
                    <Card body>
                        <CardTitle>Login</CardTitle>
                        <CardText>Email</CardText>
                        <Input style={{marginBottom: '10px'}} placeholder="Email" onChange={this.handleEmailChange}/>
                        <CardText>Wachtwoord</CardText>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            return this.postLogin();
                        }}>
                            <Input style={{marginBottom: '10px'}} placeholder="Wachtwoord" type="password"
                                   onChange={this.handlePasswordChange}
                                   onSubmit={() => this.postLogin()}/>
                        </form>
                        <Button
                            className="button"
                            size="lg"
                            block
                            onClick={() => this.postLogin()}
                        >Login</Button>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Login;
