export const staffel = (distance, staffelRows) => {
    if (!distance || distance === 'Error') {
        return 0;
    }

    for (let i in staffelRows) {
        if (staffelRows[i].upperLimit >= distance) {
            if (staffelRows[i].hasOwnProperty('value')) {
                return staffelRows[i].value;
            } else {
                return (((staffelRows[i].calculation * 100) * distance) / 100);
            }
        }
    }
};

export const defaultStaffel = {
    name: 'Default',
    staffel: [
        {
            upperLimit: 20,
            value: 185

        },
        {
            upperLimit: 40,
            value: 185
        },
        {
            upperLimit: 60,
            value: 185
        },
        {
            upperLimit: 80,
            value: 185
        },
        {
            upperLimit: 100,
            value: 185
        },
        {
            upperLimit: 120,
            value: 185
        },
        {
            upperLimit: 140,
            value: 185
        },
        {
            upperLimit: 160,
            value: 185
        },
        {
            upperLimit: 180,
            value: 185
        },
        {
            upperLimit: 200,
            value: 185
        },
        {
            upperLimit: 220,
            value: 185
        },
        {
            upperLimit: 240,
            value: 185
        },
        {
            upperLimit: 260,
            value: 185
        },
        {
            upperLimit: 280,
            value: 185
        },
        {
            upperLimit: 300,
            value: 185
        },
        {
            upperLimit: 320,
            value: 185
        },
        {
            upperLimit: 340,
            value: 185
        },
        {
            upperLimit: 360,
            value: 185
        },
        {
            upperLimit: 380,
            value: 185
        },
        {
            upperLimit: 380,
            value: 185
        },
        {
            upperLimit: 400,
            value: 185
        },
        {
            upperLimit: Infinity,
            calculation: 1.04
        }
    ]
};
