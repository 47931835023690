import React, {Component} from 'react';
import 'react-datasheet/lib/react-datasheet.css';
import './App.css';
import Header from './components/header/Header';
import {getToken} from './data/api';
import TenderHandler from './components/tenderHandler/TenderHandler';
import Login from './components/login/Login';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customers from './components/customers/Customers';
import Users from './components/users/Users';
import AddUser from './components/addUser/AddUser';
import EditUser from './components/editUser/EditUser';
import {Staffel} from './components/staffel/Staffel';
import Tenders from './components/tenders/Tenders';

class App extends Component {
    render() {
        const PrivateRoute = ({component: Component, ...rest}) => {
            return (
                <Route
                    {...rest}
                    render={props =>
                        getToken() ? (
                            <div className="app aside-menu-show" style={{display: 'flex', flexDirection: 'column'}}>
                                <Header/>
                                <div className="component-wrapper" style={{padding: '20px', boxSizing: 'border-box'}}>
                                    <Component {...props} />
                                </div>
                            </div>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: {from: props.location}
                                }}
                            />
                        )
                    }
                />
            );
        };

        return (
            <Router>
                <ToastContainer position={'top-center'}/>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <PrivateRoute exact path="/" component={Customers}/>
                    <PrivateRoute exact path="/users" component={Users}/>
                    <PrivateRoute path="/users/add" component={AddUser}/>
                    <PrivateRoute path="/users/edit/:userId" component={EditUser}/>
                    <PrivateRoute path="/tenders/:userId" component={Tenders}/>
                    <PrivateRoute path="/tenderHandler/" component={TenderHandler}/>
                    <PrivateRoute path="/staffels/" component={Staffel}/>
                </Switch>
            </Router>
        );
    }
}

export default App;
