import React, {Component} from 'react';
import ReactDataSheet from 'react-datasheet';
import {Request} from '../../data/api';
import _ from 'lodash';
import {showToast} from '../../assets/loadingToast';

class Tenders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenders: [],
            customer: null,
            showTenderForId: false
        };
    }

    async componentDidMount() {
        const customerId = _.get(this.props, 'location.state.customerId', null);
        await Request(`/customer/${customerId}`, 'GET')
            .then((response) => {
                this.setState({customer: response});
                Request(`/customer/${customerId}/tenders`, 'GET')
                    .then((response) => {
                        this.setState({tenders: response});
                    }).catch((error) => {
                    showToast('Fout bij het ophalen van tenders.\n' + error, 'error');
                });
            }).catch((error) => {
                showToast('Fout bij het ophalen van klant.\n' + error, 'error');
            });
    }

    render() {
        const renderTenders = this.state.tenders.map((tender) => {
            return (
                <li className="list-group-item" key={tender.id}>
                    <div style={{flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <h5 style={{flex: 1, marginBottom: 0}}>{tender.name}</h5>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                if (this.state.showTenderForId) {
                                    this.setState({
                                        showTenderForId: false
                                    });
                                    return;
                                }
                                this.setState({
                                    showTenderForId: tender.id
                                });

                            }}
                        >
                            Open
                        </button>
                    </div>
                    {this.state.showTenderForId === tender.id &&
                    <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                        <ReactDataSheet
                            style={{flex: 1}}
                            data={tender.tenderSheet}
                            valueRenderer={(cell) => cell.value}
                        />
                    </div>
                    }
                </li>
            );
        });
        return (
            <div>
                {this.state.customer &&
                <>
                    <h3>Tenders van {this.state.customer.name}</h3>
                    {this.state.tenders.length > 0 ?
                        <ul className="list-group" style={{maxWidth: '50%'}}>
                            {renderTenders}
                        </ul>
                        :
                        <h4>Geen tenders beschikbaar</h4>
                    }
                </>
                }
            </div>
        );
    }
}

export default Tenders;
