import _ from 'lodash';
import React, {Component} from 'react';
import {CSVLink} from 'react-csv';
import ReactDataSheet from 'react-datasheet';
import {ExcelRenderer} from 'react-excel-renderer';
import Steps from 'react-steps';
import {toast} from 'react-toastify';
import stringSimilarity from 'string-similarity';
import '../../App.css';
import {closeLoadingToast, renderLoadingToast, showToast} from '../../assets/loadingToast';
import {Request} from '../../data/api';
import {getCoordinates} from '../../data/locations';
import {defaultStaffel, staffel} from '../../data/staffel';
import {vehicleOptionsBuilder} from '../../data/vehicleOptionsBuilder';
import {VehicleConfigurator} from '../vehicleConfigurator/vehicleConfigurator';
import {Link} from 'react-router-dom';

class TenderHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dataLoaded: false,
            isFormInvalid: false,
            rows: null,
            cols: null,
            grid: [],
            routeColumns: [],
            locations: [],
            validLocations: [],
            invalidLocations: [],
            checkedInvalidLocationRows: [],
            searchString: '',
            replaceString: '',
            selectedRows: [],
            selectedColumnIndexes: [],
            apiLocations: [],
            fileName: '',
            progressCounter: 0,
            showAsideMenu: false,
            loadedRoutes: false,
            replaceStringFromSelect: '',
            truckConfig: {
                axleCount: '10',
                axleWeight: 3,
                emissionClass: 'EURO_0',
                totalWeight: 15000,
                trailerWeight: 0,
                vehicleType: 'TRU'
            },
            isExportDistance: false,
            fuelTax: 0,
            staffels: [],
            staffel: defaultStaffel,
            gettingLocations: false,
            calculatingRoutes: false
        };
        this.fileHandler = this.fileHandler.bind(this);
        this.toggle = this.toggle.bind(this);
        this.renderFile = this.renderFile.bind(this);
        this.fileInput = React.createRef();
    }

    async componentDidMount() {
        await Request('staffel', 'GET').then((response) => {
            this.setState({staffels: response});
        }).catch((error) => {
            showToast(`Fout bij het ophalen van staffels.\n${error}`, 'error');
        });
    }

    renderFile = (fileObj) => {
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({
                    fileName: fileObj.name,
                    selectedColumnIndexes: [],
                    invalidLocations: []
                }, () => this.renderGrid(resp));
            }
        });
    };

    renderGrid = (gridData, locations = false) => {
        const {validLocations} = this.state;
        const firstRow = gridData.cols.map(((column, index) => {
            return {
                value: column.name,
                component: (
                    <div className={'first-row'}> {column.name}
                        <input
                            type='checkbox'
                            style={{marginLeft: '10px'}}
                            name={column.name}
                            checked={this.state[column.name]}
                            onChange={(e) => this.toggleCheckbox(e, index)}
                        />
                    </div>
                ),
                forceComponent: true
            };
        }));
        firstRow.push({value: 'Distance (km)'}, {value: 'Time (HH:MM)'}, {value: 'Toll (\u20AC)'}, {value: 'Staffel (\u20AC)'}, {value: 'Total (\u20AC)'});
        const grid = [
            [{value: this.state.fileName, colSpan: (gridData.cols.length + 4), readOnly: true}]
        ];
        grid.push(firstRow);
        if (!locations) {
            gridData.rows.forEach((row) => {
                if (row.length > 0) {
                    if (row.length < gridData.cols.length) {
                        for (let i = row.length; i < gridData.cols.length; i++) {
                            row.push('');
                        }
                    }

                    const rowArray = [];
                    for (let i = 0; i < row.length; i++) {
                        let value;

                        value = row[i] ? row[i] : '';
                        rowArray.push({value});
                    }
                    grid.push(rowArray);
                }
            });
        } else {
            gridData.rows.forEach((row) => {
                if (row.length > 0) {
                    const rowArray = [];
                    for (let index = 0; index < row.length; index++) {
                        if (row[index]) {
                            const object = _.clone(row[index]);
                            if (_.includes(this.state.selectedColumnIndexes, index)) {
                                const isFormatted = this.isACommaSeparatedCell(row[index]);
                                let isValidLocation = {};
                                if (isFormatted) {
                                    const formattedItem = {
                                        city: (this.hasCityName(isFormatted) || '').trim().toLowerCase(),
                                        postcode: (this.hasPostCode(isFormatted) || '').trim(),
                                        country: (this.hasCountryCode(isFormatted) || '').trim()
                                    };
                                    isValidLocation = this.searchLocation(formattedItem, validLocations);
                                } else {
                                    const otherPostcode = () => {
                                        const hit = row[index].toString().match(/(?:[^A-z\u00C0-\u00FF]|^)([a-z]{0,2}\s*[0-9*-]{2,}[ \t]*[A-z]{0,2})(?:[^0-9*a-z\u00C0-\u00FF-]|$)/);
                                        if (hit && hit.length) {
                                            return hit[0];
                                        }
                                    };
                                    const inputLocation = {
                                        postcode: otherPostcode(),
                                        city: object.replace(/([^A-z\u00C0-\u00FF]|^)([a-z]{0,2}\s*[0-9*-]{2,}[ \t]*[A-z]{0,2})([^0-9*a-z\u00C0-\u00FF-]|$)/gim, function (all, start, mid, end) {
                                            return start + end;
                                        }).replace(/[^A-z\u00C0-\u00FF \n-]/gi, '').replace(/[ \t]+/g, ' ').trim()
                                    };

                                    isValidLocation = this.searchLocation(inputLocation, validLocations);
                                }

                                rowArray.push({
                                    value: isValidLocation ? `${row[index]} \r\n {city: ${isValidLocation.name}, postcode: ${isValidLocation.postcode.toString()}}` : row[index],
                                    coordinates: isValidLocation ? isValidLocation.coordinates : false,
                                    className: isValidLocation ? 'valid-location' : 'in-valid-location'
                                });
                            } else {
                                rowArray.push({
                                    value: row[index]
                                });
                            }
                        } else {
                            rowArray.push({
                                value: ''
                            });
                        }
                    }
                    grid.push(rowArray);
                }
            });
        }

        let invalidLocationRows = grid.filter((row, index) => index > 1 && row.find((cell) => {
            return cell && cell.className && cell.className === 'in-valid-location';
        }));
        let validLocationRows = grid.filter((row, index) => index > 1 && !row.find((cell) => {
            return cell && cell.className && cell.className === 'in-valid-location';
        }));

        this.setState({
            grid: [grid[0], grid[1], ...invalidLocationRows, ...validLocationRows],
            dataLoaded: true,
            cols: gridData.cols,
            rows: gridData.rows
        });
    };

    toggleCheckbox = (event, index) => {
        const {checked, name} = event.target;
        let {selectedColumnIndexes} = this.state;
        if (checked) {
            selectedColumnIndexes.push(index);
        } else {
            _.remove(selectedColumnIndexes, (index));
        }
        selectedColumnIndexes = _.uniq(selectedColumnIndexes);
        this.setState({
            selectedColumnIndexes,
            [name]: checked
        });
    };

    fileHandler = (event) => {
        if (event.target.files.length) {
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;

            if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
                this.setState({
                    uploadedFileName: fileName,
                    isFormInvalid: false
                });
                this.renderFile(fileObj);
            } else {
                this.setState({
                    isFormInvalid: true,
                    uploadedFileName: ''
                });
            }
        }
    };

    getRouteColumns = () => {
        const {cols, grid, selectedColumnIndexes} = this.state;

        if (!cols || !grid) {
            showToast('Je hebt nog geen bestand geladen.', 'error');
            return false;
        }
        if (selectedColumnIndexes.length <= 0) {
            showToast('Je hebt nog geen kolommen geselecteerd.', 'warning');
            return false;
        }

        const filteredCols = cols.filter((column, index) => _.includes(selectedColumnIndexes, index));
        const columnValues = filteredCols.map((column) => {
            let filteredGrid = grid.filter((array, index) => index > 1 && array[column.key]);
            return filteredGrid.map((array) => ({location: array[column.key]}));
        });

        this.setState({
            routeColumns: columnValues
        });
        return columnValues;
    };

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    isACommaSeparatedCell = (string) => string.split(',').length > 1 ? string.split(',') : false;

    hasPostCode = (array) => array.find((element) => element.match(/(\d+\w+)/g));

    hasCountryCode = (array) => array.find((element) => element.match(/\b[A-Z]{2}\b/g));

    hasCityName = (array) => array.find((element) => element.match(/[A-z\u00C0-\u00FF]+\s?[A-z\u00C0-\u00FF]{2,}/gu));

    analyseCell = (cell) => {
        const isCommaSeparated = this.isACommaSeparatedCell(cell);
        if (isCommaSeparated) {
            return {
                city: (this.hasCityName(isCommaSeparated) || '').trim(),
                postcode: (this.hasPostCode(isCommaSeparated) || '').trim(),
                country: (this.hasCountryCode(isCommaSeparated) || '').trim()
            };
        } else {
            let city = '';
            let postcode = '';
            const cityMatch = cell.match(/[A-z\u00C0-\u00FF]+\s?[A-z\u00C0-\u00FF]{2,}/gu);
            const postcodeMatch = cell.match(/\d{5}(?:[-]\d{4})?/);
            if (cityMatch && cityMatch.length > 0) {
                if (cityMatch.length > 1) {
                    city = cell;
                } else {
                    city = cityMatch[0];
                }
            }
            if (postcodeMatch && postcodeMatch.length > 0) {
                postcode = postcodeMatch[0];
            }

            return {
                city: city,
                postcode: postcode
            };
        }
    };

    searchLocation = (location, store) => {
        if (_.find(store, (o) => {
            if (o.hasOwnProperty('name') && o.name && location.hasOwnProperty('city') && location.city) {
                return o.name.toLowerCase() === location.city.toLowerCase();
            }
        })) {
            return _.find(store, (o) => o.name.toLowerCase() === location.city.toLowerCase());
        } else if (_.find(store, (o) => (o.hasOwnProperty('postcode') && o.postcode && location.hasOwnProperty('postcode') && location.postcode && location.postcode.match(new RegExp(o.postcode.replace(/\*/g, '.')))))) {
            let foundByPostCode = _.find(store, (o) => (location.postcode && location.postcode === o.postcode));
            if (!foundByPostCode) {
                foundByPostCode = _.find(store, (o) => (location.postcode && location.postcode.match(new RegExp(o.postcode.replace(/\*/g, '.')))));
            }
            if (location.hasOwnProperty('city') && location.city && foundByPostCode.hasOwnProperty('name') && foundByPostCode.name && stringSimilarity.compareTwoStrings(location.city.toLowerCase(), foundByPostCode.name) > 0.7) {
                foundByPostCode.aliases = [location.city.toLowerCase()];
                return foundByPostCode;
            }
        }
    };

    compareLocations = async (locations) => {
        let storedLocations = [];
        await Request('location', 'GET').then((response) => {
            const mappedLocations = response.map((item) => {
                return _.mapValues(item, value => {
                    if (value == null) {
                        return '';
                    }
                    if (typeof value === 'string') {
                        return value.toLowerCase();
                    }
                    return value;
                });
            });
            storedLocations = mappedLocations;
            this.setState({
                apiLocations: mappedLocations
            });
        }).catch((error) => {
            showToast(`Fout bij het ophalen van locaties.\n${error}`, 'error');
        });
        const newLocations = [];
        const foundLocations = [];
        for (let i = 0; i < locations.length; i++) {
            let location = locations[i];
            if (location) {
                const locationObject = this.analyseCell(location);
                const didFindLocation = this.searchLocation(locationObject, storedLocations);
                if (didFindLocation) {
                    foundLocations.push(didFindLocation);
                } else {
                    newLocations.push(locationObject);
                }
            }
        }
        this.setState({
            validLocations: [...this.state.validLocations, ...foundLocations]
        });
        return newLocations;
    };

    generateInvalidLocationsGrid = async (invalidLocations) => {
        await this.setState({
            invalidLocationsGrid: null,
            checkedInvalidLocationRows: []
        })
        let invalidLocationsGrid = [
            [{value: 'Locatie gezocht', readOnly: true}, {value: 'Locatie gevonden', readOnly: true}, {value: 'Aanpassen', readOnly: true}]
        ];
        let mappedInvalidLocations = invalidLocations.map((location, index) => {
            let searchedLocation = {value: `${location.name}, ${location.postcode}`, readOnly: true};
            let suggestedLocation = {value: `${location.suggestion.name}, ${location.suggestion.postcode}`, readOnly: true};
            let checkbox = {
                component: (
                    <div>
                        <input
                            type='checkbox'
                            style={{marginLeft: '10px'}}
                            name={location.name}
                            checked={this.state.checkedInvalidLocationRows.find(row => index === row)}
                            onChange={(e) => this.toggleInvalidLocationRow(e, index)}
                        />
                    </div>
                ),
                forceComponent: true
            };

            return [searchedLocation, suggestedLocation, checkbox];
        });

        await this.setState({
            invalidLocationsGrid: _.union(invalidLocationsGrid, mappedInvalidLocations)
        })
    };

    toggleInvalidLocationRow = (event, index) => {
        let {checked} = event.target;
        let newCheckedInvalidLocationsRows = this.state.checkedInvalidLocationRows;
        if (checked) {
            newCheckedInvalidLocationsRows.push(index);
        } else {
            newCheckedInvalidLocationsRows = newCheckedInvalidLocationsRows.filter((row) => {
                return row !== index;
            });
        }
        this.setState({
            checkedInvalidLocationRows: newCheckedInvalidLocationsRows
        });
    };

    updateInvalidLocations = async () => {
        renderLoadingToast('Niet gevonden locaties toevoegen en aanpassen...');
        let locationsToUpdate = this.state.checkedInvalidLocationRows.map((index) => this.state.invalidLocations[index]);
        let enteredLocations = locationsToUpdate.map(location => ({
            name: location.name,
            postcode: location.postcode,
            coordinates: location.suggestion.coordinates
        }));
        let suggestedLocations = locationsToUpdate.map(location => location.suggestion);
        let locationsToUpload = [...enteredLocations, ...suggestedLocations];

        let cleanApiUploadResult = await this.cleanApiUpload(locationsToUpload);

        await Request('location', 'POST', cleanApiUploadResult).then(() => {
            showToast('Locaties toegevoegd aan de database.', 'success');
        }).catch((error) => {
            showToast(`Fout bij het toevoegen van locaties aan de database.\n${error}`, 'error');
        });
        closeLoadingToast();

        let stringifyLocations = cleanApiUploadResult.map((addedLocation) => `${addedLocation.name}, ${addedLocation.postcode}`);
        await this.compareLocations(stringifyLocations);

        const enteredLocationNames = enteredLocations.map((location) => location.name);
        const updatedInvalidLocations = this.state.invalidLocations.filter((location) => !enteredLocationNames.includes(location.name))

        await this.setState({
            invalidLocations: updatedInvalidLocations,
            checkedInvalidLocationRows: []
        });

        await this.handleClick(false);
    };

    handleClick = async (getCoords = true) => {
        const data = this.getRouteColumns();
        if (!data) {
            this.setState({
                gettingLocations: false
            });
            return;
        }
        renderLoadingToast('Loading...');
        const flattenedData = [];
        _.flattenDeep(data).forEach((item) => {
            if (item.location.value !== '' &&
                (!item.location.className || _.get(item, 'location.className') !== 'valid-location') &&
                (!item.location.coordinates || _.get(item, 'location.coordinates') === false)) {
                flattenedData.push(_.get(item, 'location.value'));
            }
        });

        const unfoundLocations = await this.compareLocations(_.uniq(flattenedData));
        if (unfoundLocations && unfoundLocations.length && getCoords) {
            const newLocations = await getCoordinates(unfoundLocations);
            let cleanApiUploadResult = await this.cleanApiUpload(newLocations.valid);

            await Request('location', 'POST', cleanApiUploadResult).then(() => {
                showToast(`Locaties toegevoegd aan de database.`, 'success');
            }).catch((error) => {
                showToast(`Fout bij het toevoegen van locaties aan de database.\n${error}`, 'error');
            });

            const newArray = _.union(this.state.validLocations, newLocations.valid);
            await this.setState({
                invalidLocations: _.union(this.state.invalidLocations, newLocations.invalid),
                validLocations: newArray.sort((a, b) => a.name - b.name),
            });
        }

        if (this.state.invalidLocations.length > 0) {
            await this.generateInvalidLocationsGrid(this.state.invalidLocations);
        }

        closeLoadingToast();
        this.setState({
            gettingLocations: false
        }, () => this.renderGrid({cols: this.state.cols, rows: this.state.rows}, true));
    };

    cleanApiUpload = async (newLocations) => {
        const storedLocations = this.state.apiLocations;
        return newLocations.filter((newLocation) => {
            if (_.find(storedLocations, (location) => location.hasOwnProperty('name') && location.name && newLocation.hasOwnProperty('name') && newLocation.name && location.name.toLowerCase() === newLocation.name.toLowerCase())) {
                return false;
            } else if (_.find(storedLocations, (location) => (location.postcode && location.postcode.match(new RegExp(newLocation.postcode.replace(/\*/g, '.')))))) {
                let foundByPostCode = _.find(storedLocations, (location) => (location.postcode && location.postcode === newLocation.postcode));
                if (!foundByPostCode) {
                    foundByPostCode = _.find(storedLocations, (location) => (location.postcode && location.postcode.match(new RegExp(newLocation.postcode.replace(/\*/g, '.')))));
                }
                if (newLocation.hasOwnProperty('name') && newLocation.name && foundByPostCode.hasOwnProperty('name') && foundByPostCode.name && stringSimilarity.compareTwoStrings(newLocation.name.toLowerCase(), foundByPostCode.name) > 0.7) {
                    foundByPostCode.aliases = [newLocation.name.toLowerCase()];
                    return false;
                }
            }
            return true;
        });
    };

    allProgress = (promiseArray, progressCb) => {
        let d = 0;
        progressCb(0);
        for (const p of promiseArray) {
            p.then(() => {
                d++;
                progressCb((d * 100) / promiseArray.length);
            });
        }
        return Promise.all(promiseArray);
    };

    handleRouteClick = async () => {
        const routeData = this.getRouteColumns();
        if (!routeData) {
            this.setState({
                calculatingRoutes: false
            })
            return;
        }
        renderLoadingToast('Routes berekenen');
        const iterations = routeData[0].length;
        const requests = [];
        const countryInfoVehicleOptions = vehicleOptionsBuilder(this.state.truckConfig);

        for (let i = 0; i < iterations; i++) {
            let routeArray = [];
            routeData.forEach((column) => {
                if (column[i] && _.get(column[i], 'location.coordinates.x', false)) {
                    routeArray.push({
                        linkType: 'NEXT_SEGMENT',
                        coords: [
                            {
                                point: {
                                    x: _.get(column[i], 'location.coordinates.x'),
                                    y: _.get(column[i], 'location.coordinates.y')
                                }
                            }
                        ]
                    });
                }
            });
            requests.push(Request('location/xroute/calculate-extended-routes', 'POST', {routeArray, countryInfoVehicleOptions}));
        }
        let results = [];
        try {
            results = await this.allProgress(requests.map(p => p.catch(e => console.log('error', e))), (p) => {
                this.setState({
                    progressCounter: p.toFixed(0)
                }, () => toast.update(this.spinner, {progress: (p.toFixed(0) / 100)}));
            });
        } catch (e) {
            console.log('error', e);
        }
        const {grid} = this.state;
        let rowLength = 0;
        grid.forEach((row, index) => {
            if (row.length > rowLength) {
                rowLength = row.length;
            }
            if (row.length < rowLength) {
                for (let i = row.length; i < (rowLength - 5); i++) {
                    row.push({value: '---'});
                }
            }
            if (index > 1) {

                const distance = () => {
                    let value = _.get(results, `[${index - 2}].route.info.distance`, false);
                    if (!value) {
                        return 'Error';
                    }

                    return (value / 1000);
                };

                const time = () => {
                    const value = _.get(results, `[${index - 2}].route.info.time`, false);
                    if (!value) {
                        return 'Error';
                    }
                    return new Intl.NumberFormat('nl-NL').format(value / 3600);
                };

                const countryInfos = _.get(results, `[${index - 2}].countryInfos`, []);
                const cost = () => {
                    const sum = countryInfos.reduce((result, country) => {
                        const cost = _.get(country, 'tollTotals.cost', 0);
                        return result + cost;
                    }, 0);
                    return (sum / 100);
                };
                const distancePerCountry = countryInfos.map((country) => {
                    const formattedDistance = new Intl.NumberFormat('nl-NL').format(_.get(country, 'tollTotals.distance', 0) / 1000);
                    return {value: `${country.iuCode}: ${formattedDistance}km`};
                });

                const total = () => {
                    if (!distance()) {
                        return 'Error';
                    }
                    if (this.state.fuelTax) {
                        const fuelTaxInCents = parseInt(this.state.fuelTax) + 100;
                        return cost() + staffel(distance(), this.state.staffel.staffel) + ((distance() * fuelTaxInCents) / 100);
                    } else {
                        return cost() + staffel(distance(), this.state.staffel.staffel);
                    }
                };

                grid[index] = row.concat([
                    {value: new Intl.NumberFormat('nl-NL').format(distance())},
                    {value: time()},
                    {value: new Intl.NumberFormat('nl-NL').format(cost())},
                    {value: (distance() !== 'Error') && new Intl.NumberFormat('nl-NL').format(staffel(distance(), this.state.staffel.staffel))},
                    {value: new Intl.NumberFormat('nl-NL').format(parseFloat(total()).toFixed(2))},
                    ...distancePerCountry
                ]);
            }
            return row;
        }, grid);
        closeLoadingToast();
        this.setState({
            grid,
            progressCounter: 0,
            loadedRoutes: true,
            calculatingRoutes: false
        });

    };

    updateTruckConfig = (config) => {
        this.setState({
            truckConfig: {...config}
        });
    };

    findAndReplace = async () => {
        if (!this.state.searchString) {
            return;
        }
        if (!this.state.replaceString && !this.state.replaceStringFromSelect) {
            return;
        }

        let replaceString = '';
        let replaceObject = {};
        if (this.state.replaceString) {
            replaceString = this.state.replaceString;
            replaceObject = this.state.apiLocations && this.state.apiLocations.find((location) => location.name === replaceString.toLowerCase());
        }

        if (this.state.replaceStringFromSelect) {
            replaceString = this.state.replaceStringFromSelect;
            replaceObject = this.state.validLocations.find((location) => location.name === replaceString.toLowerCase());
        }
        const updatedRows = [];
        const {searchString} = this.state;
        let {grid} = this.state;
        grid.forEach((row) => {
            if (row.length > 0) {
                const rowArray = row.map((item) => {
                    if (item.value.toString().toLowerCase().includes(searchString.toLowerCase())) {
                        return {
                            value: replaceObject ? replaceObject.name : replaceString,
                            coordinates: replaceObject ? replaceObject.coordinates : {},
                            className: replaceObject ? 'valid-location' : 'in-valid-location'
                        };
                    } else {
                        return item;
                    }
                });
                updatedRows.push(rowArray);
            }
        });
        this.setState({
            grid: updatedRows,
            searchString: '',
            replaceString: '',
            replaceStringFromSelect: ''
        });
    };

    setSearchString = (event) => {
        this.setState({
            searchString: event.target.value
        });
    };

    setReplaceString = (event) => {
        this.setState({
            replaceString: event.target.value
        });
    };

    addFuelTax = event => {
        this.setState({
            fuelTax: event.target.value
        });
    };

    setReplaceStringFromSelect = (event) => {
        this.setState({
            replaceStringFromSelect: event.target.value
        });
    };

    setStaffel = (event) => {
        this.setState({
            staffel: event.target.value
        });
    };

    saveTender = async () => {
        const customerId = _.get(this.props, 'location.state.customerId', false);
        renderLoadingToast('Opslaan...');
        const data = {
            name: this.state.fileName,
            tenderSheet: this.state.grid.slice(2)
        };
        await Request(`customer/${customerId}/data`, 'POST', data).then(() => {
            showToast('Tender toegevoegd.', 'success');
        }).catch((error) => {
            showToast(`Probleem bij het toevoegen van tender.\n${error}`, 'error');
        });
        closeLoadingToast();
    };

    convertToCSV = (grid) => {
        if (!grid) {
            return;
        }
        return grid.map((row) => {
            return row.map((cell) => {
                return cell.value.toString();
            });
        });
    };

    render() {
        const data = [
            {
                'text': 'Selecteer een bestand',
                'isActive': !this.state.fileName,
                'isDone': this.state.fileName
            },
            {
                'text': 'Selecteer de route kolommen',
                'isActive': this.state.fileName,
                'isDone': this.state.selectedColumnIndexes.length > 1
            },
            {
                'text': 'Locaties ophalen',
                'isActive': this.state.routeColumns.length > 1 && this.state.validLocations.length === 0,
                'isDone': this.state.validLocations.length > 0
            },
            {
                'text': 'Locaties verbeteren',
                'isActive': this.state.invalidLocations.length > 0,
                'isDone': this.state.invalidLocations.length === 0 && this.state.validLocations.length > 0
            },
            {
                'text': 'Routes ophalen',
                'isActive': this.state.validLocations.length > 0,
                'isDone': this.state.loadedRoutes
            }
        ];
        return (
            <div className="app" style={{display: 'flex', flex: 1}}>
                <div className="app-body" style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                        <h3>Tender Toevoegen</h3>
                        <div id="parallelogram-container" style={{
                            flex: 1,
                            display: 'flex',
                            padding: '10px 20px',
                            flexDirection: 'row'
                        }}>
                            <Steps items={data} flat={true}/>
                        </div>


                        <div style={{flex: 1, display: 'flex', padding: '20px'}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <div className="file-select" style={{flex: 0.8}}>
                                    <div className="input-group">
                                        <label className="input-group-prepend">
                                            <span className="btn btn-primary" style={{height: '38px'}}>
                                                Selecteer&hellip;
                                                <input type="file" style={{display: 'none'}}
                                                       onChange={this.fileHandler.bind(this)}/>
                                            </span>
                                        </label>
                                        <input type="text" value={this.state.fileName} className="form-control" readOnly/>
                                    </div>
                                </div>
                                <div className="vehicle-configurator-form" style={{flex: 0.8, marginBottom: 0.5 + 'rem'}}>
                                    <VehicleConfigurator getResult={(results) => this.updateTruckConfig(results)}/>
                                </div>
                                <div className="staffel-selection" style={{flex: 0.8, marginBottom: 0.5 + 'rem'}}>
                                    {this.state.staffels && this.state.staffels.length > 0 &&
                                    <div className="input-group">
                                        <label className="input-group-prepend" style={{alignSelf: 'center'}}>
                                            Selecteer een staffel (leeg voor default):
                                        </label>
                                        <select className="form-control" id="exampleFormControlSelect2"
                                                onChange={this.setStaffel}
                                                value={this.state.staffel}>
                                            {this.state.staffels.map((item) =>
                                                <option key={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    }
                                    <Link to="/staffels"
                                          className="btn btn-primary"
                                    >Voeg Staffel toe
                                    </Link>
                                </div>
                                <div className="diesel-toeslag" style={{flex: 0.8, marginBottom: 0.5 + 'rem'}}>
                                    <div className="input-group">
                                        <label className="input-group-prepend" style={{display: 'block', width: 100 + '%'}}>
                                            Voer een diesel toeslag in (in procenten bv. 7):
                                        </label>
                                        <input
                                            type='number'
                                            className="input-group"
                                            style={{width: 'auto', display: 'inline-block'}}
                                            name={'fuelTax'}
                                            onChange={this.addFuelTax}
                                        />
                                    </div>
                                </div>
                                {this.state.dataLoaded &&
                                <div className="location-controls" style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 0.5 + 'rem'
                                }}>
                                    <button
                                        className="btn btn-primary"
                                        style={{marginRight: '10px'}}
                                        onClick={() => {
                                            this.setState({gettingLocations: true},
                                                () => this.handleClick());
                                        }}
                                        disabled={this.state.gettingLocations}
                                    >Locaties Ophalen
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        style={{marginRight: '10px'}}
                                        onClick={() => {
                                            this.setState({calculatingRoutes: true},
                                                () => this.handleRouteClick()
                                            );
                                        }}
                                        disabled={!this.state.validLocations.length > 0 || this.state.calculatingRoutes}
                                    >Routes Ophalen
                                    </button>
                                    <div className="replace-tool"
                                         style={{display: 'flex', flexDirection: 'row'}}>
                                        <div className="input-group-prepend">
                                            <input className="btn btn-primary" type="submit"
                                                   onClick={() => this.findAndReplace()}
                                                   value="Zoek en vervang"/>
                                        </div>
                                        <div style={{flex: 1}}>
                                            <input className="form-control" placeholder='Zoek naar...' type="text"
                                                   onChange={this.setSearchString} style={{'padding': '10px'}}/>
                                            <input className="form-control" placeholder='Vervang voor...'
                                                   type="text"
                                                   onChange={this.setReplaceString} style={{'padding': '10px'}}/>
                                            {!!this.state.validLocations.length > 0 &&
                                            <select className="form-control" id="exampleFormControlSelect1"
                                                    onChange={this.setReplaceStringFromSelect}
                                                    value={this.state.replaceStringFromSelect}>
                                                {this.state.validLocations.sort((a, b) => {
                                                    var nameA = a.name.toUpperCase();
                                                    var nameB = b.name.toUpperCase();
                                                    if (nameA < nameB) {
                                                        return -1;
                                                    }
                                                    if (nameA > nameB) {
                                                        return 1;
                                                    }

                                                    return 0;
                                                }).map((item, key) =>
                                                    <option key={key}>{item.name}</option>
                                                )}
                                            </select>
                                            }
                                        </div>
                                    </div>
                                </div>
                                }


                                {this.state.invalidLocations && this.state.invalidLocations.length > 0 && this.state.invalidLocationsGrid &&
                                <div className="invalid-locations-grid">
                                    <ReactDataSheet
                                        data={this.state.invalidLocationsGrid}
                                        valueRenderer={cell => cell.value}
                                        onCellsChanged={changes => {
                                            const grid = this.state.grid;
                                            changes.forEach(({row, col, value}) => {
                                                grid[row][col] = {...grid[row][col], value};
                                            });
                                            this.setState({grid});
                                        }}
                                    />
                                    <button
                                        className="btn btn-primary"
                                        style={{marginBottom: '10px', marginTop: '10px', flex: 1}}
                                        onClick={() => this.updateInvalidLocations()}
                                        disabled={this.state.checkedInvalidLocationRows.length === 0}
                                    >Locaties aanpassen
                                    </button>
                                </div>
                                }

                                {this.state.loadedRoutes &&
                                <div className="row" style={{flex: 0.8}}>
                                    <div style={{
                                        paddingLeft: '15px',
                                        paddingRight: '10px',
                                        marginRight: '10px'
                                    }}>
                                        <button
                                            disabled={!this.state.validLocations.length > 0}
                                            className="btn btn-primary"
                                            style={{marginBottom: '10px', flex: 1}}
                                            onClick={() => this.saveTender()}
                                        >Resultaten opslaan
                                        </button>
                                    </div>
                                    <div style={{
                                        paddingLeft: '15px',
                                        paddingRight: '10px',
                                        marginRight: '10px'
                                    }}>
                                        <CSVLink
                                            filename={`${this.state.fileName}.csv`}
                                            className="btn btn-primary"
                                            data={this.convertToCSV(this.state.grid)}>
                                            Resultaten downloaden
                                        </CSVLink>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {this.state.dataLoaded &&
                    <main className="main"
                          style={{
                              maxWidth: '100vw',
                              overflowX: 'scroll',
                              alignItems: 'center',
                              padding: '10px',
                              justifyContent: 'center',
                              boxSizing: 'border-box'
                          }}>
                        <ReactDataSheet
                            data={this.state.grid}
                            valueRenderer={(cell) => cell.value}
                            onCellsChanged={async changes => {
                                let updateRows = this.state.rows;
                                const grid = this.state.grid.map(row => [...row]);
                                const {invalidLocations} = this.state;
                                if (invalidLocations.length > 0) {
                                    changes.forEach(({cell, row, col, value}) => {
                                        updateRows[(row - 2)].splice(col, 1, value);
                                        grid[row][col] = {...grid[row][col], value};
                                        this.setState({
                                            searchString: cell.value,
                                            replaceString: value
                                        }, () => this.findAndReplace());
                                    });
                                } else {
                                    changes.forEach(({row, col, value}) => {
                                        updateRows[(row - 2)].splice(col, 1, value);
                                        grid[row][col] = {...grid[row][col], value};
                                    });
                                    this.setState({
                                        grid,
                                        rows: updateRows
                                    });
                                }
                            }}
                        />
                    </main>
                    }
                </div>
            </div>
        );
    }
}

export default TenderHandler;
