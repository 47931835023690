import React, {useEffect, useState} from 'react';
import {Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import useSignUpForm from '../../assets/CustomHooks';
import {Request} from '../../data/api';
import _ from 'lodash';
import {useHistory, useParams} from 'react-router-dom';
import {showToast} from '../../assets/loadingToast';

function EditUser() {
    const [currentUser, setCurrentUser] = useState('');
    const [allCustomers, setAllCustomers] = useState([]);
    const history = useHistory();

    const userId = useParams().userId;

    const {
        touched,
        inputs,
        handleInputChange,
        handleMultiselectChange,
        handleCheckboxChange,
        handleSubmit,
        handleBlur,
        updateInputs,
        isValidEmail
    } = useSignUpForm({
        firstName: '',
        lastName: '',
        email: '',
        isAdmin: false,
        customers: []
    }, () => submitForm());

    const validateForm = (inputs) => {
        return {
            firstName: (inputs.firstName.length === 0 || /\d/.test(inputs.firstName)),
            lastName: (inputs.lastName.length === 0 || /\d/.test(inputs.lastName)),
            email: !isValidEmail(inputs.email)
        };
    };
    const errors = validateForm(inputs);

    useEffect(() => {
        Request(`user/${userId}`, 'GET')
            .then((response) => {
                setCurrentUser(response);
                updateInputs({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.username,
                    isAdmin: response.isAdmin,
                    customers: response.customers.map((customer) => customer.id)
                });
            })
            .catch((error) => showToast(`Could not get user with id "${userId}".\n${error}`, 'warning'));
    }, [userId]);

    useEffect(() => {
        Request('customer', 'GET').then((response) => {
            const sortedCustomers = response.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
            setAllCustomers(sortedCustomers);
            return sortedCustomers;
        }).catch((error) => {
            showToast('Alle klanten ophalen mislukt.\n' + error, 'error');
            history.push('/');
        });
    }, []);

    const shouldMarkError = (field) => {
        const hasError = errors[field];
        const shouldShow = touched[field];
        return hasError ? shouldShow : false;
    };

    const submitForm = async () => {
        const data = {
            id: currentUser.id,
            username: inputs.email,
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            customers: inputs.customers,
            isAdmin: inputs.isAdmin
        };
        await Request(`user/${currentUser.id}`, 'PUT', data).then(() => {
            showToast('Gebruiker aangepast.', 'success');
            history.push('/users');
        }).catch((error) => {
            showToast('Gebruiker aanpassen mislukt.\n' + error, 'error');
        });
    };

    return (
        <div className="add-user-form" style={{maxWidth: '50%'}}>
            <h3>Gebruiker Aanpassen</h3>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label htmlFor="firstName">Voornaam</Label>
                    <Input
                        type="text" required placeholder="Vul je voornaam in"
                        name="firstName"
                        id="firstName"
                        value={inputs.firstName}
                        onChange={handleInputChange}
                        valid={touched['firstName'] ? !shouldMarkError('firstName') : false}
                        onBlur={() => handleBlur('firstName')}
                        invalid={shouldMarkError('firstName')}
                    />
                    <FormFeedback>Vul op z'n minst een letter in.</FormFeedback>

                </FormGroup>
                <FormGroup>
                    <Label htmlFor="lastName">Achternaam</Label>
                    <Input
                        type="text" placeholder="Vul je achternaam in" name="lastName"
                        value={inputs.lastName}
                        onChange={handleInputChange}
                        valid={touched['lastName'] ? !shouldMarkError('lastName') : false}
                        onBlur={() => handleBlur('lastName')}
                        invalid={shouldMarkError('lastName')}
                    />
                    <FormFeedback>Vul op z'n minst een letter in.</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="email">Email adres</Label>
                    <Input
                        type="text" placeholder="Vul je email adres in" name="email"
                        value={inputs.email} onChange={handleInputChange}
                        valid={touched['email'] ? !shouldMarkError('email') : false}
                        onBlur={() => handleBlur('email')}
                        invalid={shouldMarkError('email')}
                    />
                    <FormFeedback>Vul een geldig email adres in (test@test.com).</FormFeedback>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="customer-select">Selecteer klanten om aan deze gebruiker te koppelen</Label>
                    <Input type="select" name="customers" onChange={handleMultiselectChange} multiple>
                        {(allCustomers && allCustomers.length > 0) && allCustomers.map((customer, index) => {
                            return <option selected={_.find(inputs.customers, inputCustomer => inputCustomer === customer.id)}
                                           name="customers" value={customer.id} key={`customers-option-${index}`}>{customer.name}</option>;
                        })}
                    </Input>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="isAdmin" checked={inputs.isAdmin} onChange={handleCheckboxChange}/>
                        Is admin?
                    </Label>
                </FormGroup>
                <Button color="primary" size="lg" block style={{width: 'auto', marginLeft: 'auto'}}>
                    Gebruiker aanpassen
                </Button>
            </Form>
        </div>
    );
}

export default EditUser;
