import _ from 'lodash';
import {useState} from 'react';

const useSignUpForm = (initialValues, callback) => {
    const [inputs, setInputs] = useState(initialValues);
    const [touched, setTouched] = useState(_.mapValues(initialValues, () => {
            return false;
        })
    );

    const reset = () => {
        setInputs({...initialValues});
        setTouched(_.mapValues(initialValues, () => {
            return false;
        }));
    };

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        callback();
    };

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    };

    const handleDropdownChange = (event) => {
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.textContent}));
    };

    const handleCheckboxChange = (event) => {
        event.persist();
        handleBlur(event.target.name);
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.checked}));
    };

    const handleMultiselectChange = (event) => {
        event.persist();
        let newArray = [];
        let selectedOptions = event.target.selectedOptions;
        for (let i = 0; i < selectedOptions.length; i++) {
            newArray.push(selectedOptions[i].value);
        }
        handleBlur(event.target.name);
        setInputs(inputs => ({...inputs, [event.target.name]: newArray}));
    };

    const handleBlur = (field) => {
        setTouched({
            ...touched,
            [field]: true
        });
    };

    const isValidEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(email).toLowerCase());
    };

    const updateInputs = updatedInputs => {
        setInputs({...inputs, ...updatedInputs});
    };

    return {
        handleSubmit,
        handleInputChange,
        handleDropdownChange,
        handleCheckboxChange,
        handleMultiselectChange,
        handleBlur,
        isValidEmail,
        reset,
        inputs,
        touched,
        updateInputs
    };
};
export default useSignUpForm;
