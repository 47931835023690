import React from 'react';
import {toast} from 'react-toastify';
import {Spinner} from 'reactstrap';

let spinner;
export const renderLoadingToast = (message, progress = 0) => spinner =
    toast(() => <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <p>{message}</p><Spinner size="lg" color="primary"/></div>, {
        autoClose: false,
        closeOnClick: false,
        progress: progress
    });

export const closeLoadingToast = () => {
    toast.dismiss(spinner);
};

export const updateLoadingToast = (message) => {
    toast.update(spinner, {
        render: <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}><p>{message}</p>
            <Spinner size="lg" color="primary"/></div>
    });
};

export const showToast = (message, type = 'default', autoClose = 3000, options) => {
    toast(message, {type, autoClose, ...options});
};
