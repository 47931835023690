import classnames from 'classnames';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import '../../App.css';

import ChangePassword from '../changePassword/ChangePassword';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '0'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        } else {
            this.setState({
                activeTab: 0
            });
        }
    }

    render() {
        return (
            <aside className="aside-menu"
                   style={{
                       flex: 1,
                       position: 'absolute',
                       right: '0px',
                       top: '100%',
                       width: 'auto',
                       zIndex: 10,
                       border: '1px solid black',
                       padding: '10px'
                   }}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: this.state.activeTab === '3'})}
                            onClick={() => {
                                this.toggle('1');
                            }}
                        >
                            Wachtwoord Wijzigen
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <ChangePassword/>
                    </TabPane>
                </TabContent>
            </aside>
        );
    }
}

export default withRouter(Sidebar);
