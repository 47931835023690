import React, {useEffect, useState} from 'react';
import {Request} from '../../data/api';
import {Link, useHistory} from 'react-router-dom';
import {showToast} from '../../assets/loadingToast';

function Users() {
    const history = useHistory();
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        Request('user/all', 'GET').then((response) => {
            setAllUsers(response);
        }).catch((error) => {
            showToast('Alle gebruikers ophalen mislukt.\n' + error, 'error');
            history.push('/');
        });
    }, []);

    return (
        <div>
            <h3>Gebruikers</h3>
            <Link to={'/users/add'} className="btn btn-primary" style={{marginBottom: '10px'}}>Gebruiker toevoegen</Link>
            {allUsers && allUsers.length > 0 &&
            <ul className="list-group" style={{maxWidth: '50%'}}>
                {
                    allUsers.map((user) => (
                        <li className="list-group-item"
                            style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}
                            key={user.id}>
                            <div className="info" style={{display: 'flex', flexDirection: 'column'}}>
                                    <span>
                                        <strong>Gebruiker: </strong>
                                        <span className="user">
                                            {user.username}
                                        </span>
                                    </span>

                                <span>
                                        <strong>Gekoppeld aan klant: </strong>
                                        <span className="customer">
                                            {user.customers ? user.customers.reduce((acc, customer) => {
                                                if (acc !== '') {
                                                    acc += ', ';
                                                }
                                                acc += customer.name;
                                                return acc;
                                            }, '') : '-'}
                                        </span>
                                    </span>
                            </div>
                            <div className="actions">
                                <Link to={`/users/edit/${user.id}`} className="btn btn-primary">
                                    Wijzig
                                </Link>
                            </div>
                        </li>
                    ))
                }
            </ul>
            }
        </div>
    );
}

export default Users;
