import React from 'react';
import AddStaffel from './AddStaffel';

export const Staffel = () => {

    return (
        <div style={{marginLeft: '15px', marginTop: 20}}>
            <AddStaffel/>
        </div>
    );
};
