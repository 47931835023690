import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import {isAdmin, logout} from '../../data/api';

function Header() {
    const [showAsideMenu, setShowAsideMenu] = useState(false);

    const toggleAsideMenu = () => {
        setShowAsideMenu(!showAsideMenu);
    };

    return (
        <header className="app-header" style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                <div
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#282c34',
                        padding: '20px'
                    }}
                >
                    <div className="left-side">
                        <Link to="/"
                              className="btn btn-primary"
                        >Klanten
                        </Link>

                        {isAdmin() &&
                        <Link to="/users"
                              className="btn btn-primary"
                              style={{marginLeft: '10px'}}
                        >Gebruikers
                        </Link>
                        }
                    </div>

                    <div className="right-side"
                         style={{marginLeft: 'auto'}}>
                        <button
                            className="btn btn-large btn-danger"
                            onClick={() => {
                                logout();
                            }}
                        >Logout
                        </button>
                        <button
                            style={{marginLeft: '10px'}}
                            className="btn btn-secondary aside-menu-toggler"
                            type="button"
                            onClick={toggleAsideMenu}
                        >Menu
                        </button>
                        {showAsideMenu &&
                        <Sidebar/>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
