import React, {useState} from 'react';
import './vehicleConfigurator.css';

export const VehicleConfigurator = (props) => {
    const {getResult} = props;
    const [vehicleType, setVehicleType] = useState({type: 'TRL', description: 'truck with trailer'});
    const [totalWeight, setTotalWeight] = useState(40000);
    const [trailerWeight, setTrailerWeight] = useState(25000);
    const [axleWeight, setAxleWeight] = useState(9);
    const [axleCount, setAxleCount] = useState(5);
    const [emissionClass, setEmissionClass] = useState({type: 'EURO_6', description: 'Euro 6'});
    const vehicleTypes = [
        {type: 'VAN', description: 'truck less than 7.5 t total weight'},
        {type: 'TRU', description: 'truck more than 7.5 t total weight'},
        {type: 'TRL', description: 'truck with trailer'},
        {type: 'COM', description: 'combustible'},
        {type: 'HAZ', description: 'water hazardous'}
    ];
    const emissionStandards = [
        {type: 'EURO_0', description: 'Euro 0'},
        {type: 'EURO_1', description: 'Euro 1'},
        {type: 'EURO_2', description: 'Euro 2'},
        {type: 'EURO_3', description: 'Euro 3'},
        {type: 'EURO_4', description: 'Euro 4'},
        {type: 'EURO_5', description: 'Euro 5'},
        {type: 'EURO_6', description: 'Euro 6'},
        {type: 'EURO_7', description: 'Euro 7'},
        {type: 'EURO_EEV', description: 'Euro EEV'}
    ];

    const handleSubmit = (evt) => {
        evt.preventDefault();
        return getResult({vehicleType, totalWeight, trailerWeight, axleCount, axleWeight, emissionClass});
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="field">
                    <label htmlFor="vehicleType">Type</label>
                    <select className="form-control" id="vehicleType" onChange={(e) => {
                        setVehicleType(e.target.value);
                    }} value={vehicleType.type}>
                        {vehicleTypes.map((item, key) =>
                            <option value={item.type} key={key}>{item.description}</option>
                        )}
                    </select>
                </div>

                <div className="field">
                    <label htmlFor="emissionstandard">emissiestandaard</label>
                    <select className="form-control" id="emissionstandard" onChange={(e) => setEmissionClass(e.target.value)}
                            value={(emissionClass.type)}>
                        {emissionStandards.map((item, key) =>
                            <option value={item.type} key={key}>{item.description}</option>
                        )}
                    </select>
                </div>

                <div className="field">
                    <label htmlFor="axleCount">Aantal assen (>3)</label>
                    <input
                        type="number"
                        className="form-control"
                        id="axleCount"
                        onChange={(e) => setAxleCount(e.target.value)} value={axleCount}
                    />
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="field">
                    <label htmlFor="totalWeight">Totaal gewicht</label>
                    <input
                        type="number"
                        className="form-control"
                        id="totalWeight"
                        onChange={(e) => setTotalWeight(e.target.value)} value={totalWeight}
                    />
                </div>

                <div className="field">
                    <label htmlFor="trailerWeight">Trailer gewicht</label>
                    <input
                        type="number"
                        className="form-control"
                        id="trailerWeight"
                        onChange={(e) => setTrailerWeight(e.target.value)} value={trailerWeight}
                    />
                </div>

                <div className="field">
                    <label htmlFor="axleWeight">Gewicht per as</label>
                    <input
                        type="number"
                        className="form-control"
                        id="axleWeight"
                        onChange={(e) => setAxleWeight(e.target.value)} value={axleWeight}
                    />
                </div>
            </div>
            <input className="btn btn-primary" type="submit" value="Opslaan"/>
        </form>
    );
};
