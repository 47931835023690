import axios from 'axios';
import _ from 'lodash';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
});

export const Request = (url, method, data = null) => new Promise(async (resolve, reject) => {
    const headers = {};
    if (getToken()) {
        headers['Authorization'] = `Bearer ${getToken()}`;
    }

    axiosInstance({
        url,
        method,
        headers,
        data
    })
        .then((response) => {
            if (response.data) {
                resolve(response.data);
            } else {
                resolve(`${response.status} OK`);
            }
        })
        .catch((error) => {
            if (error.response) {
                ProcessError(error.response)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                reject(error);
            }
        });
});

export const ProcessError = (error) => new Promise(async (resolve, reject) => {
    switch (error.status) {
        case 401:
            reject('Je sessie is verlopen. Probeer opnieuw in te loggen.\n' + error.data.message);
            logout();
            break;
        case 403:
            setIsAdmin(false);
            reject('Niet toegestane actie.\n' + error.data.message);
            break;
        case 409:
            reject('Conflict:\n' + error.data.message);
            break;
        default:
            reject('Onverwachte fout.');
            break;
    }
});

export const getToken = () => {
    return localStorage.getItem(process.env.REACT_APP_API_KEY);
};

export const storeToken = (token) => {
    localStorage.setItem(process.env.REACT_APP_API_KEY, token);
};

export const isAdmin = () => {
    const isAdmin = localStorage.getItem(process.env.REACT_APP_IS_ADMIN);
    return isAdmin === 'true';
};

export const setIsAdmin = (isAdmin) => {
    localStorage.setItem(process.env.REACT_APP_IS_ADMIN, isAdmin);
};

export const logout = () => {
    localStorage.removeItem(process.env.REACT_APP_IS_ADMIN);
    localStorage.removeItem(process.env.REACT_APP_API_KEY);
    window.location.replace('/');
};

export const handleLocationResult = (result, originalData) => {
    const valid = [];
    const invalid = [];
    for (let currentIndex = 0; currentIndex < result.length; currentIndex++) {
        const currentValue = result[currentIndex];
        let totalScore = _.get(currentValue, 'resultList[0].totalScore', 0);
        if (totalScore > 80) {
            valid.push({
                name: originalData[currentIndex].city.toLowerCase(),
                postcode: _.get(currentValue, 'resultList[0].postCode', ''),
                coordinates: _.get(currentValue, 'resultList[0].coordinates.point', {})
            });
        } else {
            invalid.push({
                name: originalData[currentIndex].city.toLowerCase(),
                postcode: originalData[currentIndex].postCode,
                suggestion: {
                    name: _.get(currentValue, 'resultList[0].city', '').toLowerCase(),
                    postcode: _.get(currentValue, 'resultList[0].postCode', ''),
                    coordinates: _.get(currentValue, 'resultList[0].coordinates.point', {}),
                    score: totalScore
                }
            });
        }
    }
    return {valid, invalid};
};
