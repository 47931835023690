export const vehicleOptionsBuilder = (truckConfig) => {
    return [
        {
            parameter: 'TYPE',
            value: truckConfig.vehicleType
        },
        {
            parameter: 'TOTAL_WEIGHT',
            value: parseInt(truckConfig.totalWeight)
        },
        {
            parameter: 'TRAILER_WEIGHT',
            value: parseInt(truckConfig.trailerWeight)
        },
        {
            parameter: 'AXLE_WEIGHT',
            value: parseInt(truckConfig.axleWeight)
        },
        {
            parameter: 'NUMBER_OF_AXLES',
            value: parseInt(truckConfig.axleCount)
        },
        {
            parameter: 'EMISSION_CLASS',
            value: truckConfig.emissionClass
        }
    ];
};
