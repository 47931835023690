import React from 'react';

export const AddInput = ({idx, staffelState, handleStaffelChange}) => {
    const upperLimitId = `upperLimit-${idx}`;
    const valueId = `value-${idx}`;
    return (
        <li className="row" key={`row-${idx}`}>
            <div className='row m-0'>
                <div className="input-group col-sm-4">
                    <div className='row'>
                        <label className='col-sm-4'>Eindafstand (in KM)</label>
                        <input
                            className="form-control col-sm-8"
                            placeholder='100'
                            data-key='upperLimit'
                            data-idx={idx}
                            type="number"
                            name={upperLimitId}
                            value={staffelState[idx].upperLimit}
                            onChange={handleStaffelChange} style={{'padding': '10px'}}
                        />
                    </div>
                </div>
                <div className="input-group col-sm-4">
                    <div className='row'>
                        <label className='col-sm-4'>Totaalbedrag in € (bv 200)</label>
                        <input
                            className="form-control col-sm-8"
                            placeholder='100'
                            data-idx={idx}
                            data-key='value'
                            type="number"
                            name={valueId}
                            value={staffelState[idx].value}
                            onChange={handleStaffelChange} style={{'padding': '10px'}}
                        />
                    </div>
                </div>
                <div className="input-group col-sm-4">
                    <div className='row'>
                        <label className='col-sm-4'>Kilometerbedrag in € (bv 1.23)</label>
                        <input
                            className="form-control col-sm-8"
                            placeholder='100'
                            data-idx={idx}
                            data-key='value'
                            type="number"
                            name={valueId}
                            value={staffelState[idx].calculation}
                            onChange={handleStaffelChange} style={{'padding': '10px'}}
                        />
                    </div>
                </div>
            </div>
        </li>

    );
};

export default AddInput;
