import React, {useState} from 'react';
import AddInput from './AddInput';
import {Request} from '../../data/api';
import {useHistory} from 'react-router-dom';
import {showToast} from '../../assets/loadingToast';

const AddStaffel = () => {
    const history = useHistory();
    const [newStaffelName, setNewStaffelName] = useState('');

    const handleStaffelNameChange = (e) => setNewStaffelName(e.target.value);

    const emptyInput = {upperLimit: 0, value: 0};

    const [staffelState, setStaffelState] = useState([
        {...emptyInput}
    ]);

    const addRow = () => {
        setStaffelState([...staffelState, {...emptyInput}]);
    };

    const handleStaffelChange = (e) => {
        let value = e.target.value;
        let key = e.target.dataset.key;
        if (key === 'upperLimit') {
            if (value.toString() === '0') {
                value = Infinity;
            }
        }
        const updatedRows = [...staffelState];
        updatedRows[e.target.dataset.idx][key] = value;
        setStaffelState(updatedRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newStaffelName) {
            showToast('Voer een naam in.', 'warning');
            return false;
        }
        if (!staffelState.length) {
            showToast('Voeg minstens één rij toe.', 'warning');
            return false;
        }

        await Request('staffel', 'POST', {name: newStaffelName, staffel: staffelState}).then(() => {
            showToast('Staffel toegevoegd', 'success');
            history.goBack();
        }).catch((error) => {
            showToast(`Fout bij het aanmaken van staffel.\n${error}`, 'error');
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-group" style={{width: '50%', marginBottom: '10px'}}>
                <input
                    className="form-control"
                    placeholder='Nieuwe staffelnaam in (bijvoorbeeld "Voorjaar 2020 epxort tarieven")'
                    type="text"
                    onChange={handleStaffelNameChange} style={{'padding': '10px'}}
                />
            </div>
            {
                staffelState.map((val, idx) => (
                    <AddInput
                        key={`row-${idx}`}
                        idx={idx}
                        staffelState={staffelState}
                        handleStaffelChange={handleStaffelChange}
                    />
                ))
            }
            <div className='row m-0'>
                <input
                    type="button"
                    className='btn btn-primary'
                    value="Add New Row"
                    onClick={addRow}
                />
            </div>
            <div className='row mx-0 mt-5'>
                <input type="submit" className='col-sm-3 btn btn-primary' value="Opslaan"/>
            </div>
        </form>
    );
};

export default AddStaffel;
